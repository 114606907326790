import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// import styles
import './style/custom.css';
import './components/micro.css';

// import components
import LandingPage from './pages/LandingPage';
import BootcampPage from './pages/BootcampPage';


function App() {
  return (
    <Router>
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path='/:slug' element={<BootcampPage />} />
        </Routes>
    </Router>
  );
}

export default App;
