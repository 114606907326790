import React from 'react';
import './hamburger.css';

const HamburgerMenu = ({triggerFunction, trigger}) => {

  return (
    <div className="navbar-container">
        <div
          className={`navbar-toggle ${trigger ? 'active' : ''}`}
          onClick={triggerFunction}
          aria-expanded={trigger ? 'true' : 'false'}
        >
          <span className="icon-bar top-bar"></span>
          <span className="icon-bar middle-bar"></span>
          <span className="icon-bar bottom-bar"></span>
        </div>
        {/* Navigation menu items */}
      {/* <nav className={`navbar-menu ${isActive ? 'active' : ''}`}>
      </nav> */}
    </div>
  );
};

export default HamburgerMenu;
