import {
  hnhAccommodation, bankTicketingSystem,
  //  militaryRobot,
  ebenezer, esmond, japheth,
  stockpilot,
  gabrielDTH, godfredDTH,
  vision, mission, bootcamp24,
  joan, xorlali, vince, fafa
} from './images/assets';
import { faLinkedin, faInstagramSquare, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

export const cardData = [
  {
    imageSrc: hnhAccommodation,
    title: 'Domi Tech Hub',
    description: 'Domi Tech Hub is a vibrant space dedicated to fostering innovation, collaboration, and growth.',
    viewProjectLink: 'https://example.com/view-project',
    ctaText: 'disabled',
    LearnMoreLink: 'https://example.com/learn-more',
    ctaText2: 'disabled',
  },
  {
    imageSrc: stockpilot,
    title: 'StockPilot IMS',
    description: 'StockPilot IMS is the ultimate solution for streamlining your inventory processes. ' +
      'StockPilot offers intuitive tools to ensure efficient stock control, ordering, and tracking.',
    viewProjectLink: 'https://stockpilot-aivp.onrender.com/',
    ctaText: 'View Project',
    LearnMoreLink: 'https://drive.google.com/file/d/1qLLgHjMH8i0z1-_UsSL-TjQTwcprnpSN/view?usp=sharing',
    ctaText2: 'Learn More',
  },
  {
    imageSrc: bankTicketingSystem,
    title: 'Freelance Services',
    description: 'Whether you need a website, a mobile app, or web application, we can desigin and build.',
    viewProjectLink: 'https://example.com/view-project',
    ctaText: 'disabled',
    LearnMoreLink: 'https://example.com/learn-more',
    ctaText2: 'disabled',
  },
];

export const bootcampData = [
  {
    imageSrc: bootcamp24,
    slug: 'tb2024',
    title: 'Tech Bootcamp 2024',
    description: 'Join our 2024 Tech bootcamp where we explore Software Engineering, Networking & Cybersecurity, and Artificial Intelligence',
    RegisterLink: 'https://forms.gle/pCZr2kxFybgF51bH6',
    ctaText: 'Register',
    LearnMoreLink: '#',
    ctaText2: 'Learn More',
    startDate: '14th October  2024',
    packages: [
      {
        name: 'Zero to Hero',
        description: 'Perfect for individuals with no prior experience in tech, covering all fundamental concepts.',
        cost: 700,
      },
      {
        name: 'Beginner',
        description: 'Aimed at those starting out in software engineering, networking, and AI.',
        cost: 200,
      },
      {
        name: 'Intermediate',
        description: 'For participants with basic knowledge, moving towards more complex topics.',
        cost: 250,
      },
      {
        name: 'Advanced',
        description: 'In-depth exploration for experienced individuals focusing on specialized areas.',
        cost: 250,
      }
    ],
    courses: [
      {
        course_name: 'Artificial Intelligence',
        course_description: 'Learn the fundamentals of AI, including machine learning, neural networks, and their applications in various fields.',
        course_platform: 'https://chat.whatsapp.com/J8YFAP57yeKLSOHLK1XVuy',
      },
      {
        course_name: 'Software Engineering (Web Development)',
        course_description: 'Covering web development principles, full-stack development, and modern frameworks used in industry.',
        course_platform: 'https://chat.whatsapp.com/KczIpisZvtbE4JkLYKqDsi',
      },
      {
        course_name: 'Networking & Cybersecurity',
        course_description: 'Understanding network architecture, protocols, and strategies for securing networks against cyber threats.',
        course_platform: 'https://chat.whatsapp.com/BhrR2ghlpbkEqqyeWDelHj',
      }
    ]
  },
]



export const SOP = {
  mission: {
    icon: mission,
    title: 'Our Mission',
    text: [`We develop Afrocentric technological solutions that interface the global
          stage with the African continent to do business in the most prominent industries,
          especially Agriculture, Commerce & renewable energy.`,
      `It is our aim to build an innovation
          and impact driven team of excellence and to nurture the next generation of tech savvy talent
          for the world from Africa. Our technologies empower our clients to be effective
          in their business and obtain measurable results that ensure business
          exposure, growth and profitability for both of us.`]
  },

  vision: {
    icon: vision,
    title: 'Our Vision',
    text: ['We aspire to be the centre of technology to the world in Africa\'s stronghold industries.',]
  }
}

export const contactData = [
  {
    icon: faWhatsapp,
    link: 'https://chat.whatsapp.com/J6fNqf7sAOOGvHrZ0x6sbw'
  },
  {
    icon: faLinkedin,
    link: 'https://www.linkedin.com/company/domitech-hub'
  },
  {
    icon: faInstagramSquare,
    link: 'https://instagram.com/domitech_hub'
  },
];

export const teamData = [
  {
    image: ebenezer,
    name: 'Ebenezer Tseh',
    title: 'CTO'
  },
  {
    image: japheth,
    name: 'Japheth Selorm',
    title: 'CEO'
  },
  {
    image: esmond,
    name: 'Esmond Adjei',
    title: 'COO'
  },
  {
    image: joan,
    name: 'Joan Adwubi',
    title: 'CFO'
  },
  {
    image: vince,
    name: 'Vince Ankrah',
    title: 'Technical Lead'
  },
  {
    image: xorlali,
    name: 'Xorlali Agbemasu',
    title: 'Digital Marketing Manager'
  },
  {
    image: fafa,
    name: 'Fafa Aboagye',
    title: 'Software Developer'
  }
];

export const testimonialData = [
  {
    image: gabrielDTH,
    name: 'Gabriel Nii',
    beneficiary: 'DTH, Q4 2023',
    text: `Learning with DTH has been a life changing experience.
           With the skills I gained from DTH I have scaled from Zero to hero.
           I have from then built so many projects. Cheers to DTH🥂`
  },
  {
    image: godfredDTH,
    name: 'Godfred Annum Jr.',
    beneficiary: 'DTH, Q3 2024',
    text: `I came to Domitech Hub as a biology major with zero coding experience.
           Their incredible tutors made me realize programming isn't just for the tech-inclined.
           Bugs nearly drove me crazy, but with their help, 
           I built my first web app! If you doubt your ability to learn to code, 
           Domitech Hub will prove you wrong. They turned my expectations into something even more valuable.`
  }
];
