import React from "react";

const PaymentSuccessModal = ({ course, closeModal }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-btn" onClick={closeModal}>×</button>
        <h2>Course WhatsApp Page</h2>
        <h4>You've taken the first step in kickstarting your tech career with Domitech Hub 🤩</h4>
        <p>
          Kindly follow this link to join your <strong>{course.course_name}</strong> course platform: <br/>
          <a href={course.course_platform} target="_blank" rel="noopener noreferrer">
            {course.course_name} WhatsApp page
          </a>
        </p>
      </div>
    </div>
  );
};

export default PaymentSuccessModal;