import React from 'react';


const ProfileCard = ({profileImage, name, title}) => {

  return (
    <div className='image-card'>
    <div style={{backgroundImage: `url(${profileImage})`}} className='image-card-img'/>
    <div className='image-card-text'>
        <h3>{name}</h3>
        <small>{title}</small>
    </div>
    </div>
  )
}

export default ProfileCard;