import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faCreditCard, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// Data
import { bootcampData } from '../content_data';
import PaymentForm from '../components/PaymentForm';

const BootcampPage = () => {

  const { slug } = useParams();
  const [bootcamp, setBootcamp] = useState(null);
  const [displayPaymentForm, setDisplayPaymentForm] = useState(false);

  const togglePaymentForm = () => {
    setDisplayPaymentForm(!displayPaymentForm);
  }

  const navigate = useNavigate();

  useEffect(() => {
    const selectedBootcamp = bootcampData.find(bootcamp => bootcamp.slug === slug);
    setBootcamp(selectedBootcamp);
  }, [slug]);

  if (!bootcamp) {
    return <div>Loading...</div>; // Handle loading state
  }

  return (
    <div className="bootcamp__content">
      <div className="bootcamp__header">
        <div className="back__arrow" onClick={() => navigate('/')}>
          <FontAwesomeIcon icon={faArrowLeft} className='back__arrow'/>
        </div>
        <h2 className="bootcamp__title">{bootcamp.title}</h2>
      </div>
      <img src={bootcamp.imageSrc} alt={bootcamp.title} className="bootcamp__image" />
      <p className="bootcamp__description regular__text">{bootcamp.description}</p>
      <p className="bootcamp__start-date">
        Start Date: <span className="info">{bootcamp.startDate}</span>
      </p>

      {/* Call to action buttons */}
      <div className="bootcamp__buttons">
        <a href={bootcamp.RegisterLink} className="bootcamp__button btn-light" target="_blank" rel="noreferrer">
          {bootcamp.ctaText} <FontAwesomeIcon icon={faExternalLinkAlt} className="bootcamp__icon" />
        </a>
        <div className="bootcamp__button btn-dark" onClick={togglePaymentForm}>
          Make Payment <FontAwesomeIcon icon={faCreditCard} className="bootcamp__icon" />
        </div>
      </div>

      <h3>Our Courses</h3>
      <div className="bootcamp__courses">
        {bootcamp.courses.map((course, index) => (
          <div key={index} className="bootcamp__course">
            <h4 className="info">{course.course_name}</h4>
            <p className="regular__text">{course.course_description}</p>
          </div>
        ))}
      </div>

      <h3>Packages</h3>
      <div className="bootcamp__packages">
        {bootcamp.packages.map((pkg, index) => (
          <div key={index} className="bootcamp__package">
            <h4 className="info">{pkg.name}</h4>
            <p className="regular__text">{pkg.description}</p>
            <p>
              <strong>Cost:</strong> <span className="info">{pkg.cost} Cedis (+ 1.5% service charge)</span>
            </p>
          </div>
        ))}
      </div>

      {/* Call to action buttons */}
      <div className="bootcamp__buttons">
        <a href={bootcamp.RegisterLink} className="bootcamp__button btn-light" target="_blank" rel="noreferrer">
          {bootcamp.ctaText} <FontAwesomeIcon icon={faExternalLinkAlt} className="bootcamp__icon" />
        </a>
        <div className="bootcamp__button btn-dark" onClick={togglePaymentForm}>
          Make Payment <FontAwesomeIcon icon={faCreditCard} className="bootcamp__icon" />
        </div>
      </div>
      {
        displayPaymentForm && (
          <PaymentForm 
            courses={bootcamp.courses} 
            packages={bootcamp.packages}
            onClose={togglePaymentForm}  
          />
        )
      }
    </div>
  );
};

export default BootcampPage;
