import React from 'react';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const BootcampCard = ({ imageSrc, title, description, ctaText, RegisterLink, ctaText2, LearnMoreLink, courses, onLearnMore }) => {
  return (
    <div className="bootcamp-card">
      <div className="bootcamp-card__image">
        <img src={imageSrc} alt={title} height={200} width={200} style={{ borderRadius: "8px" }} />
      </div>
      <div className="bootcamp-card__details">
        <h2 className="bootcamp-card__title">{title}</h2>
        <p className="bootcamp-card__description">{description}</p>

        {/* Display courses offered by bootcamp */}
        <p style={{ fontWeight: "600", color: "var(--light-50)" }}>Our Courses</p>
        <div className="bootcamp__courses">
          {courses.map((course, index) => (
            <div key={index} className="bootcamp__course">
              {course.course_name}
            </div>
          ))}
        </div>

        <div className="bootcamp-card__links">
          <a href={RegisterLink} className="bootcamp-card__link btn-transparent" target='_blank' rel="noreferrer">
            {ctaText} <FontAwesomeIcon icon={faExternalLinkAlt} className="bootcamp-card__link-icon" />
          </a>
          <a href={LearnMoreLink} className="bootcamp-card__link btn-transparent" onClick={onLearnMore}>
            {ctaText2} <FontAwesomeIcon icon={faExternalLinkAlt} className="bootcamp-card__link-icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default BootcampCard;