import React from 'react';
import { useNavigate } from 'react-router-dom';
import BootcampCard from './BootcampCard';

const BootcampContainer = ({bootcampData}) => {
  const navigate = useNavigate();

  return (
    <div style={{display: "flex", flexWrap: "wrap", gap: "20px"}}>
      {bootcampData.map((bootcamp, index) => (
        <BootcampCard
          key={index}
          imageSrc={bootcamp.imageSrc}
          title={bootcamp.title}
          description={bootcamp.description}
          ctaText={bootcamp.ctaText}
          RegisterLink={bootcamp.RegisterLink}
          ctaText2={bootcamp.ctaText2}
          LearnMoreLink={bootcamp.LearnMoreLink}
          courses={bootcamp.courses}
          onLearnMore={() => navigate(`/${bootcamp.slug}`)}
        />
      ))}
    </div>
  );
};

export default BootcampContainer;
