import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { PaystackButton } from 'react-paystack';

// Component
import PaymentSuccessModal from './PaymentSuccessModal';


const PaymentForm = ({ courses, packages, onClose }) => {

  console.log("Courses: ", courses);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    course: '',
    package: '',
  };

  const [ displayPaymentSuccessModal, setDisplayPaymentSuccessModal ] = useState(false); // State to render payment success modal
  const [selectedCourse, setSelectedCourse] = useState(null); // State to set the registered course displayed in the modal

  // 

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.string().required('Phone number is required'),
    course: Yup.string().required('Please select a course'),
    package: Yup.string().required('Please select a package'),
  });

  const calculateAmount = (selectedPackage) => {
    const selectedPkg = packages.find((pkg) => pkg.name === selectedPackage);
    return selectedPkg ? (selectedPkg.cost + ((1.5/100) * selectedPkg.cost)) * 100 : 0; // Convert to kobo for Paystack
  };

  const handleSubmit = async (values) => {
    console.log('Form submitted with values: ', values);
  };

  return (
    <div className="modal__overlay">
      <div className="modal__content">
        <button className="modal__close" onClick={onClose}>
          x
        </button>
        <h2 className="form-title">Complete Bootcamp Payment</h2>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, isValid }) => (
            <Form>
              {/* First Name */}
              <label htmlFor="firstName" className="form__label">First Name</label>
              <Field name="firstName" type="text" className="form__field" />
              <ErrorMessage className="error-message" name="firstName" component="div" />

              {/* Last Name */}
              <label htmlFor="lastName" className="form__label">Last Name</label>
              <Field name="lastName" type="text" className="form__field" />
              <ErrorMessage className="error-message" name="lastName" component="div" />

              {/* Email */}
              <label htmlFor="email" className="form__label">Email</label>
              <Field name="email" type="email" className="form__field" />
              <ErrorMessage className="error-message" name="email" component="div" />

              {/* Phone */}
              <label htmlFor="phone" className="form__label">Phone Number</label>
              <Field name="phone" type="tel" className="form__field tel" />
              <ErrorMessage className="error-message" name="phone" component="div" />

              {/* Course Selection */}
              <label htmlFor="course" className="form__label">Select Course</label>
              <Field as="select" name="course" className="form__field select">
                <option value="">Select a course</option>
                {courses.map((course, index) => (
                  <option key={index} value={course.course_name}>
                    {course.course_name}
                  </option>
                ))}
              </Field>
              <ErrorMessage className="error-message" name="course" component="div" />

              {/* Package Selection */}
              <label htmlFor="package" className="form__label">Select Package</label>
              <Field as="select" name="package" className="form__field select">
                <option value="">Select a package</option>
                {packages.map((pkg, index) => (
                  <option key={index} value={pkg.name}>
                    {pkg.name} - {pkg.cost} GHC (+ 1.5% service charge)
                  </option>
                ))}
              </Field>
              <ErrorMessage className="error-message" name="package" component="div" />

              {/* Payment Button */}
              <div className="bootcamp__buttons">
                {isValid && values.course && values.package && (
                  <PaystackButton
                    className='bootcamp__button btn-dark'
                    email={values.email}
                    amount={calculateAmount(values.package)} // Amount in kobo for Paystack
                    publicKey={process.env.REACT_APP_PAYSTACK_PUBLIC_KEY}
                    text="Complete Payment"
                    currency='GHS'
                    metadata={{
                      firstName: values.firstName,
                      lastName: values.lastName,
                      phone: values.phone,
                      course: values.course,
                      package: values.package,
                    }}
                    onSuccess={() => {
                      const selected = courses.find(course => course.course_name === values.course); 
                      setSelectedCourse(selected); // Set the course after payment success
                      console.log("Selected course: ", selectedCourse)
                      setDisplayPaymentSuccessModal(true); // Display success modal

                      if (selected && selected.course_platform) {
                        window.open(selected.course_platform, '_blank');
                      }
                    }}
                    onClose={() => alert('Payment was not completed.')}
                  />
                )}
              </div>
            </Form>
          )}
        </Formik>
          {
            displayPaymentSuccessModal && (
              <PaymentSuccessModal 
                course={selectedCourse}
                closeModal={() => setDisplayPaymentSuccessModal(false)}
              />
            )
          }
      </div>
    </div>
  );
};

export default PaymentForm;
