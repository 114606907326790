import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';


const Card = ({ imageSrc, title, description, ctaText, viewProjectLink, ctaText2, LearnMoreLink }) => {
  return (
    <div className="card">
        <div className='card__image' style={{backgroundImage: `url(${imageSrc})`}}> 
        </div>
      <div className="card__details">
        <h2 className="card__title">{title}</h2>
        <p className="card__description">{description}</p>
        <div className="card__links">
          { ctaText !== 'disabled' &&
          <a href={viewProjectLink} className="card__link btn-transparent" target='_blank' rel="noreferrer">
            {ctaText} <FontAwesomeIcon icon={faExternalLinkAlt} className="card__link-icon"/>
          </a>
          }
          { ctaText2 !== 'disabled' &&
          <a href={LearnMoreLink} className="card__link btn-transparent" target='_blank' rel="noreferrer">
            {ctaText2} <FontAwesomeIcon icon={faExternalLinkAlt} className="card__link-icon"/>
          </a>
          }
        </div>
      </div>
    </div>
  );
};

export default Card;