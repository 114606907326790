import React from 'react';
import { bannerImage } from '../images/assets';

function Billboard() {
    return (
        <div className="banner" id="home" style={{backgroundImage: `url(${bannerImage})`}}>
          <div className="overlay"></div>
            <div className="banner-text">
                <h1> <span className='handwritten'>Welcome to</span> <br />DOMI TECHNOLOGIES</h1>
                <p>Innovate • Empower • Transform</p>
            <span className="cta-container">
              <a href='#projects'>
                  <button className="btn">Our Services</button>
              </a>
              <a href='#contact'>
                  <button className="btn btn-transparent">Learn More</button>
              </a>
            </span>
            </div>
        </div>
    );
}

export default Billboard;
