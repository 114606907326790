import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

function EmailForm() {
    const form = useRef();
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Use your EmailJS service credentials
        emailjs.sendForm('domitech_landing_page', 'template_ndoxqdq', form.current, 'ah6Y4NqbcjfGQp9Se')
          .then((result) => {
              console.log(result.text);
              setSubmitted('success');
              setMessage('Message sent successfully!');
          }, (error) => {
              console.log(error.text);
              setSubmitted('error');
              setMessage('An error occurred, please try again');
        });
    };

    return (
        <form className="email-form" ref={form} onSubmit={handleSubmit} id="contact">
            <h2>Send us a message</h2>
            <label htmlFor="name">Full Name:</label>
            <input type="text" id="name" name="from_name" required placeholder='Enter your name...' />

            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="from_email" required placeholder='eg: example@gmail.com' />

            <label htmlFor="email">Subject:</label>
            <input type="text" id="subject" name="from_subject" placeholder='eg: Meeting Schedule' />

            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" rows="4" required placeholder='Start writing something ...'></textarea>
            <button type="submit" className='btn'>Send</button>

            {submitted && <p className={`notify ${submitted === 'error'? 'fail' : 'success'}`}>{message}</p>}

        </form>
    );
}

export default EmailForm;
