import React, { useState, useEffect, useRef } from 'react';
import HamburgerMenu from '../components/hamburger';
import {logo} from '../images/assets';

function Header() {
    const [isActive, setIsActive] = useState(false);
    const [activeSection, setActiveSection] = useState('home');
    const headerRef = useRef(null);
  
    useEffect(() => {
      const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.3,
      };
  
      const handleIntersection = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      };
  
      const observer = new IntersectionObserver(handleIntersection, observerOptions);
  
      const sections = document.querySelectorAll('section');
      sections.forEach((section) => observer.observe(section));
  
      return () => {
        sections.forEach((section) => observer.unobserve(section));
      };
    });

    return (
    <header ref={headerRef}>
        <div className={`header ${isActive ? 'active' : ''}`}>
            <img src={logo} alt='golden logo' className='logo' />
            <HamburgerMenu triggerFunction={() => setIsActive(!isActive)} trigger={isActive}/>

            <nav className={`navbar-menu ${isActive ? 'active' : ''}`}>
                <ul>
                    <li><a href="#home" className={`nav__links ${activeSection === 'home' ? ' active' : ''}`} >Home</a></li>
                    <li><a href="#projects" className={`nav__links ${activeSection === 'projects' ? ' active' : ''}`}>Projects</a></li>
                    <li><a href="#vm" className={`nav__links ${activeSection === 'vm' ? ' active' : ''}`}>Vision & Mission</a></li>
                    <li><a href="#team" className={`nav__links ${activeSection === 'team' ? ' active' : ''}`}>Team</a></li>
                    <li><a href="#contact" className={`nav__links ${activeSection === 'contact' ? ' active' : ''}`}>Contact Us</a></li>
                </ul>
            </nav>
        </div>
    </header>
    );
}

export default Header;
