import React from 'react'

// import components
import Header from '../containers/Header';
import Billboard from '../containers/Billboard';
import MainContent from '../containers/Content';
import Footer from '../containers/Footer';

const LandingPage = () => {
  return (
    <div>
        <Header />
        <Billboard />
        <MainContent />
        <Footer />
    </div>
  )
}

export default LandingPage